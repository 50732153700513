import React from "react"
import { observer, inject } from "mobx-react"
import Download from "./components/Download"

const App = inject("shop")(
    observer(({ shop }) => (
        <div>
            <div className="App">
                <AppMenu>
                    <AppMenuItem onClick={() => shop.view.openDownloadPage()}>
                        Download
                    </AppMenuItem>
                    {renderPage(shop.view)}
                </AppMenu>
            </div>
        </div>
    ))
)

function renderPage(viewStore) {
    switch (viewStore.page) {
        case "download":
          return <Download/>
        default:
            return "Sry, not found"
    }
}

const AppMenu = ({ children }) => <ul className="App-menu">{children}</ul>

const AppMenuItem = ({ onClick, children }) => (
    <li>
        <a onClick={onClick}>{children}</a>
    </li>
)

export default App
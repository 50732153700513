import { types, getEnv } from "mobx-state-tree"
import { ViewStore } from "./ViewStore"

export const ApplicationStore = types
    .model("ApplicationStore", {
        view: types.optional(ViewStore, {})
    })
    .views((self) => ({
        get fetch() {
            return getEnv(self).fetch
        },
        get alert() {
            return getEnv(self).alert
        },
    }))
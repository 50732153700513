import React, { useEffect } from "react"
import { observer, inject } from "mobx-react"
import { isAndroid, isIOS } from "react-device-detect";

const Download = inject("shop")(
    observer(({ shop }) => {
        document.getElementById('loader').src = 'ludensoexplore://launch?downloadExperienceUid=123'+window.location.search+window.location.hash;
        
        var fallbackLink = isAndroid ? 'https://play.google.com/store/apps/details?id=com.ludenso.ludensoexplorelight' : "https://apps.apple.com/us/app/ludenso-explore/id1586988323" ;
            /*
            if (isAndroid) {
                var now = new Date().valueOf();
                setTimeout(function () {
                    if (new Date().valueOf() - now > 100) return;
                    window.location = "https://play.google.com/store/apps/details?id=com.ludenso.ludensoexplorelight";
                    }, 25);
                window.location = "ludensoexplore://launch?downloadExperienceUid=123";
            }
            else if(isIOS) {
                var now = new Date().valueOf();
                setTimeout(function () {
                    if (new Date().valueOf() - now > 100) return;
                    window.location = "https://apps.apple.com/us/app/ludenso-explore/id1586988323";
                    }, 25);
                window.location = "ludensoexplore://launch?downloadExperienceUid=123";    
            } else {
                console.log("Not an Android or iOS device");
            }
            */
         
        window.setTimeout(function (){ window.location.replace(fallbackLink); }, 1);
        console.log("Downloadpage")
        return (
        <section className="Page-books">
            <h1>Download</h1>
            <ol>
                
            </ol>
        </section>
    )
})
)

export default Download
import { types, getParent } from "mobx-state-tree"

export const ViewStore = types
    .model({
        page: "df",
        selectedBookId: ""
    })
    .views((self) => ({
        get shop() {
            return getParent(self)
        },
        get currentUrl() {
            switch (self.page) {
                case "download":
                    return "/download"
                default:
                    return "/404"
            }
        },
    }))
    .actions((self) => ({
        openDownloadPage(){
            self.page = "download"
        }
    }))